<template>
    <div class="el-content">
        <a-form :label-col="{span:3}" :wrapper-col="{span:12}">
            <a-tabs defaultActiveKey @change="getSetData">
                <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.tab">
                    <!-- 基本设置 -->
                    <div v-if="item.key == 1 ">
                        <com-power-form :form-data="[
							{label:'包邮方式',type:'radio',value:'',key:'farm_delivery_method',dataType:'string',
                                options:[
                                    {label:'满件包邮',value:'1'},
                                    {label:'满额包邮',value:'2'},
                                ]
                            },
                            {label:'全场满件包邮',type:'number',value:'',key:'farm_delivery_jian',dataType:'string',addon:'件'},
                            {label:'全场满额包邮',type:'number',value:'',key:'farm_delivery_yuan',dataType:'string',addon:'元'},
						]"></com-power-form>
                    </div>
                    <div v-if="item.key == 2">
                        <page-freight></page-freight>
                    </div>
                    <div v-if="item.key == 3" >
                        <div class="tips">
                            <p>1、达达开放平台注册以及使用方法 <a href="https://www.yuque.com/docs/share/e2dc681b-90d3-4da6-8894-ed550a014e99?#" target="_blank">《达达同城跑腿配送》</a></p>
                            <p>2、达达开放平台网址<a href="http://newopen.imdada.cn" target="_blank">http://newopen.imdada.cn</a></p>
                        </div>
                        <div v-if="$store.state.modulesPerm.salesroom == 1">

                            <com-power-form :form-data="[
                                {label:'达达域名',type:'select',value:'',key:'dada_url',dataType:'string',
                                    options:[
                                        {label:'测试域名',value:'https://newopen.qa.imdada.cn'},
                                        {label:'正式域名',value:'https://newopen.imdada.cn'},
                                    ],
                                    tips:'应用测试环境请选择达达测试域名,应用正式环境请选择达达正式域名'
                                },
                                {label:'达达app_key',type:'text',value:'',key:'dada_app_key',dataType:'string'},
                                {label:'达达app_secret',type:'text',value:'',key:'dada_app_secret',dataType:'string'},
                                {label:'达达商户编号',type:'text',value:'',key:'dada_source_id',dataType:'string'},
                            ]"></com-power-form>
                        </div>
                        <!-- 未开通门店功能 -->
                        <div style="padding: 20px;" v-else>
                            <div class="cds-paotui">由于您未开通门店功能，暂无法使用该服务!</div>
                            <router-link to="/plugin/list">
                                <a-button type="primary">立即开通</a-button>
                            </router-link>
                        </div>
                    </div>
                    <div v-if="item.key == 4">
                        <page-merchant-delivery></page-merchant-delivery>
                    </div>
                    <div v-if="item.key == 5">
                        <div class="tips">
                            <p>1、本接口功能主要是用户快递物流的即时查询</p>
                            <p>2、快递鸟详细技术文档请查看：<a target="_blank" href="http://www.kdniao.com/api-track">http://www.kdniao.com/api-track</a></p>
                            <p>3、阿里物流技术文档连接：<a target="_blank" href="https://market.aliyun.com/products/56928004/cmapi021863.html#sku=yuncode1586300000">https://market.aliyun.com/products/56928004/cmapi021863.html#sku=yuncode1586300000</a></p>
                            <p>电子面单配置说明如下：</p>
                            <p>1、快递公司配置是为了打印电子面单而使用,如不需要使用电子面单功能，可不设置此项！</p>
                            <p>2、快递公司配置需要您请仔细填写，并填写正确！</p>
                            <p>3、快递公司配置项说明：
                                <a-tooltip placement="right">
                                    <template #title>
                                        <div><img src="@/assets/img/express/express-1.png" alt="" /></div>
                                    </template>
                                    <span style="margin-right:8px;color: #177CE3;">支持的快递公司</span>
                                </a-tooltip>
                                <a-tooltip placement="right">
                                    <template #title>
                                        <div> <img src="@/assets/img/express/express2.png" alt="" /> </div>
                                    </template>
                                    <span style="margin-right:8px;color: #177CE3;">快递公司简称</span>
                                </a-tooltip>
                                <a-tooltip placement="right">
                                    <template #title>
                                        <div> <img src="@/assets/img/express/express3.png" alt="" /> </div>
                                    </template>
                                    <span style="margin-right:8px;color: #177CE3;">需要填写的字段说明</span>
                                </a-tooltip>
                            </p>
                            <p>4、打印方式支持<a  target="_blank" href="http://www.lodop.net/">lodop插件</a>(可打印多张) 打印和 浏览器打印（每次只能打印一张）</p>
                            <p>5、电子面单打印仅支持快递鸟物流</p>
                            <p>6、<a target="_blank" href="https://www.yuque.com/docs/share/630f0649-49b5-461a-a6e7-ddec42d418ca?#">电子面单配置说明</a></p>
                        </div>
                        <a-card title="快递鸟电子面单" class="mb12">
                            <a-form-item label="物流APPID">
                                <a-input v-model:value="logisticsForm.logistcs_appid"></a-input>
                            </a-form-item>
                            <a-form-item label="密钥">
                                <a-input v-model:value="logisticsForm.logistcs_key"></a-input>
                            </a-form-item>
                            <a-form-item label="回调地址">
                                <a-input v-model:value="logisticsForm.logistcs_notify"></a-input>
                            </a-form-item>
                            <a-form-item label="打印环境配置">
                                <a-radio-group v-model:value="logisticsForm.logistcs_print_env">
                                    <a-radio value="http://sandboxapi.kdniao.com:8080/kdniaosandbox/gateway/exterfaceInvoke.json">测试环境
                                    </a-radio>
                                    <a-radio value="http://api.kdniao.com/api/EOrderService">正式环境
                                    </a-radio>
                                </a-radio-group>	
                            </a-form-item>
                            
                            <a-form-item label="发货地址">
                                <com-select-city v-model="logisticsForm.logistcs_send_region"></com-select-city>
                            </a-form-item>
                            <a-form-item label="详细地址">
                                <a-input v-model:value="logisticsForm.logistcs_send_address"></a-input>
                            </a-form-item>
                            <a-form-item label="发货地址邮编">
                                <a-input v-model:value="logisticsForm.logistcs_send_postcode"></a-input>
                            </a-form-item>
                            <a-form-item label="联系人">
                                <a-input v-model:value="logisticsForm.logistcs_send_username"></a-input>
                            </a-form-item>
                            <a-form-item label="联系人">
                                <a-input v-model:value="logisticsForm.logistcs_send_mobile"></a-input>
                            </a-form-item>
                            <a-form-item label="快递公司">
                                <template v-if="logisticsForm.logistcs_express.length > 0">
                                    <div class="express-item" v-for="(val,ind) in logisticsForm.logistcs_express" :key="ind">
                                        <a-input style="width: 200px;" v-model:value="val.name" placeholder="快递公司名称"></a-input>
                                        <a-input style="width: 200px;" v-model:value="val.short_name" placeholder="快递公司简称"></a-input>
                                        <a-input style="width: 200px;" v-model:value="val.customerName" placeholder="CustomerName"></a-input>
                                        <a-input style="width: 200px;" v-model:value="val.customerPwd" placeholder="CustomerPwd"></a-input>
                                        <a-input style="width: 200px;" v-model:value="val.monthCode" placeholder="MonthCode"></a-input>
                                        <a-input style="width: 200px;" v-model:value="val.sendSit" placeholder="SendSit"></a-input>
                                        <i class="ri-delete-bin-line" @click="delExpress(ind)"></i>
                                    </div>
                                </template>
                                <div class="express-add" @click="addExpress"><i class="el-icon-plus"></i>添加快递公司</div>
                            </a-form-item>
                        </a-card>
                        <a-card title="阿里云物流">
                            <a-form-item label="阿里物流APPID">
                                <a-input v-model:value="logisticsForm.logistcs_ali_appid"></a-input>
                            </a-form-item>
                        </a-card>
                        <a-form-item :wrapper-col="{offset:3}">
                            <a-button type="primary" @click="submitSave">保存</a-button>
                        </a-form-item>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </a-form>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import rh from '@/router/routerHandle.js'
import set from "@/api/set.js"
import comSelectCity from '@/components/public/com-select-city.vue'
import pageFreight from '@/components/layout/set/page-freight.vue'
import pageMerchantDelivery from '@/components/layout/set/page-merchant-delivery.vue'
import comPowerForm from '@/components/form/com-power-form.vue'
export default {
    components:{
        comSelectCity,
        pageFreight,
        pageMerchantDelivery,
        comPowerForm
    },
    setup() {
        const _d = reactive({
            tabs:[],
            active:"",
            logisticsForm:{	//物流信息
                logistcs_appid:'',
                logistcs_key:'',
                logistcs_notify:'',
                logistcs_ali_appid:'',
                logistcs_express:[],
                logistcs_send_region:[],
                logistcs_send_address:'',
                logistcs_send_postcode:"",
                logistcs_send_username:"",
                logistcs_send_mobile:"",
                logistcs_print_env:""
            },
        })

        _d.tabs = rh.getCuurentTabs("delivery")
		if( _d.tabs.length > 0 ) getSetData(_d.tabs[0].key)
        function getSetData(_a){
            let keys = []
			_d.active = _a
            if( _a == 2 ) return
            if( _a == 5 ) keys = Object.keys(_d.logisticsForm)
            
            set.getFarmSet(keys).then(res=>{
                if(_a == 1 ) _d.freightForm = { ...res }
                if(_a == 3 ) _d.dadaForm = { ...res }
                if(_a == 5 ) {
                    _d.logisticsForm = { ...res }
                    if( res.logistcs_express ){
                        _d.logisticsForm.logistcs_express = JSON.parse(res.logistcs_express)
                    }else{
                         _d.logisticsForm.logistcs_express= []
                    }
                    if( res.logistcs_send_region ){
                        _d.logisticsForm.logistcs_send_region = JSON.parse(res.logistcs_send_region)
                    }else{
                        _d.logisticsForm.logistcs_send_region = []
                    }
                    //判断是否存在地区信息
                    if( !res.logistcs_send_region ){
                        _d.logisticsForm.logistcs_send_region = []
                    }else{
                        //地址转换
                        _d.logisticsForm.logistcs_send_region = JSON.parse(res.logistcs_send_region)
                    }
                }
            })
        }
        function submitSave(){
            let _a = _d.active , param = null
            if( _a == "5" ) param = _d.logisticsForm
            set.setFarmSet(param)
        }

        function addExpress(){
            _d.logisticsForm.logistcs_express.push({
                name:'',
                short_name:'',
                customerName:'',
                customerPwd:'',
                monthCode:'',
                sendSit:''
            })
        }

        function delExpress(index){
            _d.logisticsForm.logistcs_express.splice(index,1)
        }

        return {
            ...toRefs(_d),
            submitSave,
            getSetData,
            addExpress,
            delExpress
        }
    },
}
</script>
<style lang="scss" scoped>
.express-item{
    display: flex;
    width: 70%;
    align-items: center;
    margin-bottom: 12px;
}
.express-add{
    width: 70%;
    text-align: center;
    height: 40px;
    border: 1px dashed #f4f4f4;
    color: #999;
    margin-top: 12px;
    line-height: 40px;
    cursor: pointer;
}
</style>