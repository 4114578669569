<template>
    <a-cascader v-model:value="value" :options="cityOptions" placeholder="请选择地址" @change="getCity"/>
</template>
<script>
import { reactive, toRefs, watch } from 'vue'
import cityOptions from "@/util/city.js"
export default {
    name:'com-select-city',
    props:{
        modelValue:[Array,Object],
        default:[]
    },
    setup(props,context) {
        const data = reactive({
            value:props.modelValue,
            cityOptions:cityOptions
        })

        watch(()=>props.modelValue,(val)=>{
            data.value = val
        })

        function getCity(){
            context.emit("update:modelValue",data.value)
        }
        return {
            ...toRefs(data),
            getCity
        }
        
    },
}
</script>