<template>
    <div class="merchant-delivery">
        <div class="tips">
            <p>1、只有在门店下单的订单才能只用达达商家自送服务，不是门店商品或订单只能使用快递发货</p>
            <p>2、授权微信公众号是用于绑定配送员的openid ,从而进行配送订单消息配送</p>
        </div>
        <a-space style="margin:12px 0;">
            <a-input-search 
                style="width:400px;"
                v-model:value="search.key" 
                placeholder="搜索配送员名称/电话" 
                enter-button 
                @search="getDeliveryMan(1,info.limit)" 
            />
            <a-button type="primary" 
                @click="editDeliveryman" 
                v-has="{action:'wxapp_delivery_edit_deliveryman',plat:0}">
                <i class="ri-add-line"></i>
                添加配送员
            </a-button>	
        </a-space>
        <a-table row-key="id" :pagination="false" :data-source="info.list" :columns="[
            {title:'ID',dataIndex:'id'},
            {title:'user_id',dataIndex:'account_id'},
            {title:'配送员',dataIndex:'name'},
            {title:'联系方式',dataIndex:'mobile'},
            {title:'所属门店',dataIndex:'outlet.name'},
            {title:'待配送订单',dataIndex:'to_delivery_num'},
            {title:'配送中订单',dataIndex:'in_delivery_num'},
            {title:'已完成订单',dataIndex:'quantityorder'},
            {title:'状态',dataIndex:'status',slots:{customRender:'status'}},
            {title:'最后更新时间',dataIndex:'update_time'},
            {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
        ]" class="mt12">
            <template #status="{record}">
                <a-tag :color="record.status == 1 ? '#00CC66':'#999'">
                    {{record.status == 1 ? '正常':'禁用'}}
                </a-tag>
            </template>
            <template #action="{record}">
                <a-space>
                    <a-button type="link" @click="editDeliveryman(record)" v-has="{action:'wxapp_delivery_edit_deliveryman',plat:0}">编辑</a-button>
                    <a-button type="link" @click="getOfficalAuth(record.id)" v-has="{action:'wxapp_delivery_auth_deliveryman',plat:0}">授权公众号</a-button>
                </a-space>
            </template>
        </a-table>
        <div class="pager">
            <a-pagination
                show-size-changer
                :default-current="info.page"
                :total="info.count"
                @showSizeChange="(p,e)=>{getDeliveryMan(info.page,e)}"
                @change="(e)=>{getDeliveryMan(e,info.limit)}"
            />
        </div>
        <a-modal v-model:visible="show.delivery" title="添加配送员" @ok="saveDeliveryman" @cancel="show.delivery = false" width="700px">
            <a-form :label-col="{span:4}" :wrapper-col="{span:16}">
                <a-form-item label="配送员姓名">
					<a-input placeholder="输入配送员姓名" v-model:value="form.name"></a-input>
				</a-form-item>
				<a-form-item label="配送员电话">
					<a-input placeholder="输入配送员联系电话" v-model:value="form.mobile"></a-input>
				</a-form-item>
				<a-form-item label="所属门店" name="outlet_id">
					<a-select v-model:value="form.outlet_id" placeholder="请选择所属门店">
					    <a-select-option
							v-for="(item,index) in outlet"
							:key="index"
							:value="item.id">{{item.name}}
					    </a-select-option>
					  </a-select>
				</a-form-item>
				<a-form-item label="客户端用户">
					<com-select-user :value="form.account_id" @change="e=>form.account_id = e "></com-select-user>
				</a-form-item>
				<a-form-item label="微信公众号openid">
					<a-input placeholder="微信公众号openid" v-model:value="form.openid"></a-input>
				</a-form-item>
				<a-form-item label="状态">
					<a-radio-group v-model:value="form.status">
					      <a-radio :value="1">正常</a-radio>
					      <a-radio :value="0">禁用</a-radio>
					</a-radio-group>
				</a-form-item>
            </a-form>
        </a-modal>

        <a-modal v-model:visible="show.qrcode" title="公众号授权二维码" :footer="null" @cancel="show.qrcode = false" width="300px">
			<div style="width: 100%;text-align: center;">
				<div style="color: #E74C3C;">扫码授权接收配送订单信息</div>
				<a-image style="width: 200px; height: 200px" :src="show.qrcode_url"/>
			</div>
		</a-modal>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import setModel from '@/api/set.js'
import outletModel from '@/api/addons/outlet.js'
import comSelectUser from '@/components/miniapp/com-select-user.vue'
export default {
    name:"page-merchant-delivery",
    components:{
        comSelectUser
    },
    setup() {
        const _d = reactive({
            search:{
                key:""
            },
            info:{
                list:[],
                page:1,
                count:10,
                size:10
            },
            show:{
                delivery:false,
                qrcode:false,
                qrcode_url:""
            },
            form:{
                id:0,
                name:'',
                mobile:"",
                openid:'',
                status:0,
                outlet_id:"",
                account_id:"",
            },
            outlet:[],
        })
        getDeliveryMan(1,10)
        function getDeliveryMan(page,limit){
            setModel.getMerchantDeliveryMan(page,limit,_d.search,res=>{
                _d.info.list = res.list
                _d.info.count = res.count
            })
        }

        function editDeliveryman(e){
            _d.form = {
                id:e ? e.id : 0 ,
                name:e ? e.name : "",
                openid:e ? e.openid : "",
                mobile:e ? e.mobile : "",
                status:e ? e.status : 0,
                outlet_id:e ? e.outlet_id : "",
                account_id:e ? e.account_id : "",
            }
            _d.show.delivery = true

            if( _d.outlet.length == 0 ){
                outletModel.getOutletList(1,99,null,res=>{
                    _d.outlet = res.list
                })
            }
        }

        function saveDeliveryman(){
            setModel.addMerchantDeliveryMan(_d.form,()=>{
                _d.show.delivery = false
                getDeliveryMan(_d.info.page,_d.info.limit)
            })
        }

        function getOfficalAuth(id){
            setModel.getPersonWxAuthQrcode(id,res=>{
                _d.show.qrcode_url = res
				_d.show.qrcode = true
            })
        }

        return {
            ...toRefs(_d),
            getDeliveryMan,
            editDeliveryman,
            saveDeliveryman,
            getOfficalAuth
        }
    },
}
</script>